.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;

    > .col,
    > [class*='col-'] {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.form-control {
    display: block;
    outline: none;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &::placeholder {
        color: #c1c2c4;
        font-size: 0.85rem;
    }

    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .input-group-prepend {
        margin-right: -1px;
    }

    .input-group-append,
    .input-group-prepend {
        display: flex;
    }

    .input-group-append:last-child
        > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group-append:last-child > .input-group-text:not(:last-child),
    .input-group-append:not(:last-child) > .btn,
    .input-group-append:not(:last-child) > .input-group-text,
    .input-group-prepend > .btn,
    .input-group-prepend > .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }

    .custom-file,
    .custom-select,
    .form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}

.form-check-input {
    width: 2em;
    height: 2em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    color-adjust: exact;

    &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;

    .form-check-input {
        float: left;
        margin-left: 1.5em * -1;

        &:checked[type='checkbox'] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }
}

//
// Switch
//

.form-switch {
    padding-left: 2.5em;

    .form-check-input {
        width: 2em;
        margin-left: 2.5em * -1;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
        background-position: left center;
        border-radius: 2em;
        transition: background-position 0.15s ease-in-out;

        &:focus {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e") !important;
        }

        &:checked {
            background-position: right center;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
        }
    }
}

.form-control.is-valid,
.ng-select.is-valid .ng-select-container,
.was-validated .form-control:valid {
    border-color: #198754;
}

.form-control.is-invalid,
.ng-select.is-invalid .ng-select-container,
.was-validated .form-control:invalid {
    border-color: #dc3545;
}
